import React from "react"
import BlogLayout from "../../components/Blog/BlogLayout"
import Header from "../../components/Header"
import BlogContentContainer from "../../components/Blog/BlogContentContainer"
import BlogContent from "../../components/Blog/BlogContent"
import Footer from "../../components/Footer"
import { IndexQuery } from "../../components/Query/IndexPage"
import { connect } from "react-redux"

const Blog = ({ language, dispatch }) => {
  const data = IndexQuery()

  let dataLang = {}

  data.allContentfulIndex.nodes.forEach(node => {
    if (language === node.node_locale) {
      dataLang = node
    }
  })

  return (
    <BlogLayout>
      <Header
        indexPage={false}
        data={dataLang.header}
        dispatch={dispatch}
        language={language}
      />
      <BlogContentContainer>
        <BlogContent data={dataLang.blog} language={language} />
      </BlogContentContainer>
      <Footer data={dataLang.footer} />
    </BlogLayout>
  )
}

export default connect(state => state.languageReducer, null)(Blog)
