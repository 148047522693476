import React from "react"
import Article from "../../contentfulModels/Blog/Article"

import "./BlogContent.scss"

const BlogContent = ({ data, language }) => {
  return (
    <div className="blog__container">
      <div className="blog__container_container">
        <div className="blog__title">
          <h4>{data.title}</h4>
        </div>
        <section className="blog__table">
          {data.article.map(
            ({
              id,
              titleAndLink,
              image,
              tags,
              tagsInEnglish,
              date,
              summary,
            }) => (
              <Article
                key={id}
                id={id}
                title={titleAndLink.name}
                url={titleAndLink.url}
                imageSrc={image.file.url}
                tagsInEnglish={tagsInEnglish}
                tags={tags}
                date={date}
                summary={summary ? summary.summary : null}
                language={language}
              />
            )
          )}
        </section>
      </div>
    </div>
  )
}

export default BlogContent
